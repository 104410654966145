import { call, put, takeLatest } from "redux-saga/effects";
import apis from "services/user";
import authSlice from "../slice/authSlice";

const { actions } = authSlice;

export function* getBusinesses() {
  yield put(actions.fetchBusinessesEnd());
}

export function* logout(action) {
  try {
    const response = yield call(apis.logoutUser, action.payload);
    yield put(actions.logoutSuccess(response));
  } catch (e) {
    yield put(actions.logoutSuccess(e?.response?.data));
  }
}

export function* authSaga() {
  yield takeLatest(actions.fetchBusinesses.type, getBusinesses);
  yield takeLatest(actions.logout.type, logout);
}
