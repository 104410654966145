export const pageCount = 20;

export const IS_DEV = [
  "testflight.getbani.com",
  "localhost",
  "playground.bani.africa",
].includes(window.location.hostname);

export const NAIRA_ABBR = "NGN";

export const teamMemberDefaultActionsState = (isAccount = false) => {
  return {
    can_add: false,
    can_authorize: false,
    can_read: isAccount,
    can_delete: false,
    can_edit: false,
  };
};

export const pendingTransactionStatus = [
  "in_progress",
  "source_processing",
  "pending",
  "on_going",
];
export const sucessfulTransactionStatus = ["completed", "paid"];
export const failedTransactionStatus = ["failed"];
export const partPaidTransactionStatus = ["on_going", "part_payment"];
export const isRequired = { required: true };
export const REQUEST_TYPES = {
  POST: "POST",
};

export const MERCHANTS_MODAL_TYPES = {
  SEARCH_FILTER: "SEARCH_FILTER",
  MECHANT_TRANSACTIONS_FILTER: "MECHANT_TRANSACTIONS_FILTER",
};

export const STAT_DATE_TYPE = {
  MONTHLY: "monthly",
  WEEKLY: "weekly",
};
