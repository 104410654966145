import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  user: null,
  otp_details: null,
  verifyingOtp: false,
};

const signInSlice = createSlice({
  name: "signIn",
  initialState,
  reducers: {
    signInUser: (state) => {
      return {
        ...state,
        loading: true,
        user: null,
        error: null,
      };
    },
    signInUserSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
        verifyingOtp: false,
      };
    },
    signInUserError: (state, action) => {
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
      };
    },
    requireOtp: (state, action) => {
      return {
        ...state,
        loading: false,
        otp_details: action.payload,
      };
    },
    verifyOtp: (state) => {
      state.verifyingOtp = true;
    },
    resetOtpState: (state) => {
      state.loading = false;
      state.error = false;
      state.verifyingOtp = false;
      state.otp_details = null;
    },
    verifyOTPError: (state) => {
      state.verifyingOtp = false;
    },
  },
});

export default signInSlice;
