/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useParams, useLocation } from "react-router";
import _ from "lodash";
import InflowOverview from "./InflowOverview";
import RecentTransactions from "./RecentTransactions";
import MerchantDetails from "./MerchantDetails";
import BreadCrumbs from "components/General/BreadCrumbs";
import { ReactComponent as Filter } from "assets/icons/Filter/filter.svg";
import Button from "components/General/Button/Button";
import { paramsObjectToQueryString } from "utils/request";
const MerchantProfile = () => {
  const params = useParams();
  const location = useLocation();
  const { state } = location;
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <div className="flex flex-col justify-start items-start h-fit w-full space-y-7 md:pr-4">
      <div className="flex flex-col md:flex-row justify-start items-start h-full w-full space-y-7 md:space-y-0 md:space-x-5 md:mb-14 lg:mb-10 pr-2">
        <BreadCrumbs
          className=""
          links={[
            {
              name: "Merchants",
              link: !_.isEmpty(state)
                ? `/dashboard/merchants#${paramsObjectToQueryString(state)}`
                : "/dashboard/merchants",
            },
            { name: "Merchant Account" },
          ]}
        />
        <Button
          text="Filter"
          whiteBg
          icon={<Filter className="stroke-current" />}
          onClick={() => setModalVisible(true)}
        />
      </div>
      <div className="flex flex-col md:flex-row justify-start items-start h-full w-full space-y-7 md:space-y-0 md:space-x-5 mb-10 md:mt-10 pr-2 relative">
        {/* Left column */}

        <div className="flex flex-col w-full md:!w-[62%] md:!max-w-[62%] lg:!w-[67%] lg:!max-w-[67%] justify-start items-center h-full gap-y-7">
          {/* Overview */}
          <InflowOverview
            tribe_account_id={params.tribe_account_id}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
          />
          {/* Recent transactions table */}
        </div>

        <div className="flex flex-col w-full md:!w-[38%] md:!max-w-[38%] lg:!w-[33%] lg:!max-w-[33%]   justify-start items-center h-full space-y-7">
          {/* Merchant Details */}
          <MerchantDetails tribe_account_id={params.tribe_account_id} />
        </div>
      </div>

      <RecentTransactions tribe_account_id={params.tribe_account_id} />
    </div>
  );
};
export default MerchantProfile;
