import { useDispatch, useSelector } from "react-redux";
import authSlice from "redux/auth/slice/authSlice";
import {
  clearUserDetails,
  getToken,
  getUserInfoFromStorage,
} from "../utils/storage";
import { setToken } from "../utils/apiInstance";

const { actions: authActions } = authSlice;

function useAuth() {
  const { currentUser } = useSelector((state) => ({
    currentUser: state.auth.currentUser,
  }));
  const { currentBusiness } = useSelector((state) => state.auth);
  const { tribe_account_id, account_tribe_ref } = currentBusiness;
  const dispatch = useDispatch();
  const userData = getUserInfoFromStorage();
  const token = getToken();

  function checkSessionValidity() {
    try {
      return !!token;
    } catch (err) {
      return false;
    }
  }

  async function logout() {
    try {
      clearUserDetails();
      dispatch(
        authActions.logout({
          headers: { Authorization: `Token ${token}` },
        })
      );
    } catch (error) {
      return error;
    }
  }

  function setAuthenticatedUser(result) {
    dispatch(authActions.setCurrentUser(result));
  }

  function initUserSession() {
    if (checkSessionValidity()) {
      setAuthenticatedUser({ ...userData, token });
      setToken(token);
      dispatch(authActions.fetchBusinesses());
      return;
    }
    setAuthenticatedUser({});
  }

  return {
    currentUser,
    checkSessionValidity,
    initUserSession,
    setAuthenticatedUser,
    isAuthenticated: checkSessionValidity(),
    logout,
    tribe_account_id,
    account_tribe_ref,
    currentBusiness,
  };
}

export { useAuth };
