import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchBusinesses } from "redux/auth/slice/authSlice";
import {
  saveToken,
  saveUserInfoToStorage,
  clearAccountCreation,
} from "utils/storage";
import { setToken } from "utils/apiInstance";
import { useAuth } from "./auth";

function useLoginSetup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setAuthenticatedUser } = useAuth();

  const logUserIn = (user, route) => {
    if (user) {
      const { token, ...rest } = user;
      saveToken(token);
      setToken(token);
      saveUserInfoToStorage(rest);
      setAuthenticatedUser(user);
      dispatch(fetchBusinesses());
      clearAccountCreation();
      navigate(route || "/dashboard/merchants", { replace: true });
    }
  };

  return {
    logUserIn,
  };
}

export default useLoginSetup;
