import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { ReactComponent as ArrowDownIcon } from "assets/icons/Arrow/arrow-icon.svg";
import useWindowDimensions from "hooks/useWindowDimensions";

const Accordion = ({ status, title, body, children }) => {
  const content = useRef(null);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [height, setHeight] = useState("0px");
  const [active, setActive] = useState(true);

  const toggleAccordion = () => {
    setActive((prev) => !prev);
    setHeight(!active ? "0px" : `${content.current.scrollHeight}px`);
  };

  return (
    <div className="w-full flex flex-col justify-start items-start bg-white py-3.5 sm:py-5 rounded-lg overflow-hidden border-1/2 border-grey-bordercolor sm:border-none">
      <div
        className={`w-full z-[9] ${
          !active ? "sm:border-b border-grey-light sm:pb-5 " : ""
        }`}
      >
        <button
          className="w-full flex justify-between items-start cursor-pointer px-3.5 sm:px-5"
          onClick={() =>
            width >= 640
              ? toggleAccordion()
              : navigate(
                  title?.includes("holder")
                    ? "holder"
                    : title?.includes("Bank")
                    ? "bvn"
                    : "business"
                )
          }
        >
          <div className="w-full flex justify-start items-start">
            <div className="flex justify-start items-start pt-1 sm:pt-2 w-9 md:w-12 !min-w-[36px] !max-w-[36px] md:min-w-[48px] md:max-w-[48px] h-12">
              <div
                className={`w-6 h-6 rounded-3xl -mb-6 ${
                  status === `pending`
                    ? `warning `
                    : status === `rejected`
                    ? `!bg-red `
                    : status === `accepted`
                    ? `!bg-green-light `
                    : `!bg-grey-bordercolor`
                }`}
              />
            </div>
            <div className="flex flex-col justify-start items-start text-left gap-2 sm:gap-0">
              <div className="flex justify-between sm:justify-start items-start sm:items-center w-full sm:w-fit text-left space-x-2">
                <h3 className="flex justify-start items-center text-left text-sm">
                  {title}
                </h3>
                <div className="flex justify-start items-center text-left space-x-2 text-xs font-light capitalize sm:pt-1">
                  <div
                    className={`text-${
                      status === `pending`
                        ? ` warning-text `
                        : status === `rejected`
                        ? `red `
                        : status === `accepted`
                        ? `green-light `
                        : `white`
                    } text-lg mr-2`}
                  >
                    •
                  </div>
                  {status}
                </div>
              </div>
              <p className="text-grey-text text-xs font-thin">{body}</p>
            </div>
          </div>

          <button
            className={`hidden sm:flex justify-center items-center transition-transform ease-in-out duration-300 transform ${
              active ? "-rotate-180" : "rotate-0"
            }`}
          >
            <ArrowDownIcon className="stroke-current h-3.5 w-3.5" />
          </button>
        </button>
      </div>

      {/* Accordion children */}

      <div
        ref={content}
        style={{ maxHeight: `${height}`, opacity: active ? 0 : 1 }}
        className="transition-all duration-200 ease-out w-full hidden sm:block"
      >
        {children}
      </div>

      {/* Accordion children end */}
    </div>
  );
};

Accordion.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.elementType,
};

export default Accordion;
