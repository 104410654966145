import { call, put, takeLatest } from "redux-saga/effects";

import apis from "../../services/misc";
import miscSlice from ".";

const { actions } = miscSlice;

export function* businessCategories(action) {
  try {
    const response = yield call(apis.businessCategories, action.payload);
    yield put(actions.businessCategoriesSuccess(response));
  } catch ({ response }) {
    const { data } = response;
    yield put(actions.businessCategoriesError(data));
  }
}

export function* miscSaga() {
  yield takeLatest(actions.businessCategories.type, businessCategories);
}
