import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,
  transactions: [],
  graphData: {},
  transactionDetails: null,
  merchantDetails: {},
  searchResults: null,
  searchResultsCount: 0,
  searching: false,
  searchError: false,
  exporting: false,
  exportResults: "",
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    getMerchantsList: (state) => {
      state.loading = true;
    },
    getMerchantsListSuccess: (state, action) => {
      state.loading = false;
      state.transactions = action.payload;
    },
    getMerchantsListError: (state, action) => {
      state.loading = false;
      state.transactions = null;
    },
    getTransactionDetails: (state) => {
      state.loading = true;
      state.transactionDetails = null;
    },
    getTransactionDetailsSuccess: (state, action) => {
      state.loading = false;
      state.transactionDetails = action.payload;
    },
    getTransactionDetailsError: (state, action) => {
      state.loading = false;
      state.transactionDetails = null;
    },
    searchMerchants: (state) => {
      state.searching = true;
    },
    searchMerchantsError: (state) => {
      state.searching = false;
      state.searchError = true;
      state.searchResults = null;
    },

    searchMerchantsSuccess: (state, action) => {
      state.searching = false;
      state.searchError = false;
      state.searchResults = action.payload?.data;
      state.searchResultsCount = action.payload?.count;
    },

    emptyMerchantsList: (state) => {
      state.searchResults = [];
      state.searchResultsCount = 0;
    },

    exportAccountMerchants: (state) => {
      state.exporting = true;
      state.exportResults = "";
    },
    exportAccountMerchantsSuccess: (state, action) => {
      state.exporting = false;
      state.exportResults = action.payload;
    },
    exportAccountMerchantsError: (state, action) => {
      state.exporting = false;
      state.exportResults = "";
    },
    resetExportAccountMerchants: (state) => {
      state.exporting = false;
      state.exportResults = "";
    },
    resetSearchState: (state) => {
      state.searching = false;
      state.searchError = false;
      state.searchResults = null;
    },

    getMerchantDetails: (state) => {
      state.loading = true;
    },
    getMerchantDetailsSuccess: (state, action) => {
      state.loading = false;
      state.merchantDetails = action.payload.data;
    },
    getMerchantDetailsError: (state, action) => {
      state.loading = false;
      state.merchantDetails = {};
    },

    getMerchantTransactions: (state) => {
      state.loading = true;
    },
    searchMerchantTransactions: (state) => {
      state.loading = true;
    },
    getMerchantTransactionsSuccess: (state, action) => {
      state.loading = false;
      state.transactions = action.payload;
    },
    getMerchantTransactionsError: (state, action) => {
      state.loading = false;
      state.transactions = null;
    },

    getMerchantTransactionsGraphData: (state) => {
      state.loading = true;
    },
    getMerchantTransactionsGraphDataSuccess: (state, action) => {
      state.loading = false;
      state.graphData = action.payload.inflow
        ? action.payload.inflow
        : action.payload.outflow;
    },
    getMerchantTransactionsGraphDataError: (state, action) => {
      state.loading = false;
      state.graphData = {};
    },
  },
});

export default transactionsSlice;
