import PropTypes from "prop-types";
import { ReactComponent as Logos } from "assets/logos/faint-black.svg";
import { IS_DEV } from "utils/appConstant";
import { OnboardingDefaultWrapper } from "./style";

export default function Index({ children }) {
  return (
    <OnboardingDefaultWrapper
      className={`${
        IS_DEV ? "h-with-test-banner" : "h-screen w-screen"
      } bg-grey-whitesmoke`}
    >
      <div className="default-content">
        <div className="bani-logo-container flex justify-center">
          <Logos className="bani-logo" />
        </div>

        <div className="md:p-8 py-8 px-3 bg-white w-full rounded-lg form-container">
          {children}
        </div>
      </div>
    </OnboardingDefaultWrapper>
  );
}

Index.propTypes = {
  children: PropTypes.elementType,
};
