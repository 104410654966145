import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import qs from "query-string";
import useTableFilter from "hooks/tableFilter";
import ActiveFilter from "components/General/ActiveFilter";
import TableManipulation from "components/General/Table/TableManipulation";
import CircleLoader from "components/General/CircleLoader/CircleLoader";
import Table from "components/General/Table";
import SearchBar from "components/General/Searchbar/SearchBar";
import { MERCHANTS_MODAL_TYPES, pageCount } from "utils/appConstant";
import { hasValue } from "utils/validations";
import { numberWithCommas } from "utils/formatter";
import { ReactComponent as SearchIcon } from "assets/icons/SearchIcon/searchIcon.svg";
import useWindowDimensions from "hooks/useWindowDimensions";
import transactionsSlice from "../slice";
import FilterModal from "./FilterModal";
import { getMerchantDetails, getMerchantStatus } from "utils/functions";
import { paramsObjectToQueryString } from "utils/request";

const { SEARCH_FILTER } = MERCHANTS_MODAL_TYPES;
export default function MerchantsList() {
  const requiredFilters = {
    start_date: "2020-01-01",
    end_date: moment().format("YYYY-MM-DD"),
  };

  const defaultFilters = {
    start_date: "",
    end_date: "",
    tx_verb: "",
    fiat_wallet_id: "",
    coin_wallet_id: "",
    account_status: "",
  };

  const { width } = useWindowDimensions();
  const [modalVisible, setModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const { actions } = transactionsSlice;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchResults, searchResultsCount, searching } = useSelector(
    (state) => state.transactions
  );

  const params = qs.parse(location.hash?.substring(1));

  const searchQuery = searchInput?.trim();
  const isSearchMode = searchQuery?.length > 1 && searchResults;

  const {
    filterInput,
    setFilterInput,
    filterData,
    handleFilter,
    clearFilters,
    onRemoveFilter,
  } = useTableFilter({
    defaultFilters,
    setFilterModal: setModalVisible,
    currentPage,
    setCurrentPage,
    params,
  });

  const fetchMerchants = () => {
    const filters = {
      start_date: hasValue(filterData?.start_date)
        ? filterData?.start_date
        : requiredFilters.start_date,
      end_date: hasValue(filterData?.end_date)
        ? filterData?.end_date
        : requiredFilters.end_date,
      ...(hasValue(searchQuery) && {
        account_trade_name: searchQuery,
      }),
      ...(hasValue(filterData?.account_status) && {
        account_status: filterData.account_status.value,
      }),
      account_type: "merchant",
    };

    const paramsData = {
      ...filters,
    };

    if (
      _.isEqual(
        { start_date: paramsData.start_date, end_date: paramsData.end_date },
        requiredFilters
      )
    ) {
      delete paramsData.start_date;
      delete paramsData.end_date;
    }

    window.location.hash = paramsObjectToQueryString(paramsData);

    dispatch(
      actions.searchMerchants({
        data: filters,
        page: currentPage,
      })
    );
  };

  useEffect(() => {
    if (searchQuery) {
      setCurrentPage(1);
    }
  }, [searchInput]);

  useEffect(() => {
    fetchMerchants();
  }, [currentPage, filterData]);

  useEffect(() => {
    if (searchQuery?.length > 1 || !searchQuery) {
      fetchMerchants();
    }
  }, [searchInput]);

  const columns = [
    {
      name: "Name",
      selector: "account_trade_name",
      sortable: true,
    },

    {
      name: "Phone number",
      selector: (row) =>
        getMerchantDetails(row?.account_holder_details, "phone"),
      sortable: true,
    },
    {
      name: "Email address",
      selector: (row) =>
        getMerchantDetails(row?.account_holder_details, "email"),
      sortable: true,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.pub_date).format("DD MMM YYYY"),
      sortable: true,
    },
    {
      name: "Last Modified Date",
      selector: (row) => moment(row.modified_date).format("DD MMM YYYY"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => getMerchantStatus(row?.account_status),
      sortable: true,
    },
  ];

  const viewMerchantDetails = ({ tribe_account_id }) => {
    dispatch(actions.emptyMerchantsList());
    navigate(`/dashboard/merchants/${tribe_account_id}`, {
      state: { ...params },
    });
  };

  const containsActiveFilter = () =>
    Object.keys(filterData).filter(
      (item) => filterData[item] && filterData[item] !== ""
    );

  const renderFilters = () => {
    if (filterData) {
      return containsActiveFilter().map((item) => {
        const hasChanged = defaultFilters[item] !== filterData[item];
        if (hasChanged) {
          return (
            <ActiveFilter
              key={item}
              type={_.lowerCase(item).replace(/ /g, " ")}
              value={
                moment(filterData[item]?.value || filterData[item]).isValid()
                  ? filterData[item]?.value || filterData[item]
                  : _.lowerCase(filterData[item]?.value).replace(/ /g, " ")
              }
              onRemove={() => onRemoveFilter(item)}
            />
          );
        }
        return null;
      });
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => scrollToTop(), [searchResults]);
  return (
    <>
      <div className="h-full md:pr-4">
        <div className="flex flex-col justify-start items-center h-full w-full space-y-7">
          <div className="flex justify-between items-center w-full">
            <h3 className="text-xl whitespace-nowrap">
              {isSearchMode
                ? `Search results - ${numberWithCommas(searchResultsCount)}`
                : "Merchants"}
            </h3>
          </div>
          <div className="flex flex-col justify-start items-center w-full space-y-3 md:hidden">
            <div className="h-[44px] w-full">
              <SearchBar
                placeholder="Search by merchant business name"
                onChange={setSearchInput}
                value={searchInput}
                className="md:hidden"
                isLoading={searching}
              />
            </div>

            <TableManipulation
              onFilter={() => setModalVisible(true)}
              className="md:hidden"
              searchLoading={searching}
            />
          </div>

          <TableManipulation
            placeholder="Search by merchant business name"
            onSearchChange={setSearchInput}
            onFilter={() => setModalVisible(true)}
            searchValue={searchInput}
            className="hidden md:flex"
            searchClass=""
            searchLoading={searching}
          />
          {searching ? (
            <CircleLoader blue />
          ) : (
            <>
              {containsActiveFilter().length > 0 && (
                <div className="active-filters-container flex items-center w-full">
                  <p className="title-text mr-[8px] text-blue">Filters:</p>
                  <div className="active-filter-list flex items-center space-x-[8px]">
                    {renderFilters()}
                  </div>
                </div>
              )}

              <div className="flex flex-col flex-grow justify-start items-center w-full h-full rounded-lg">
                {searchResults?.length > 0 ? (
                  <Table
                    data={searchResults?.length ? searchResults : []}
                    columns={width >= 640 ? columns : columns.slice(0, 2)}
                    onRowClicked={viewMerchantDetails}
                    pointerOnHover
                    isLoading={searching}
                    pageCount={searchResultsCount / pageCount}
                    onPageChange={(page) => setCurrentPage(page)}
                    currentPage={currentPage}
                    tableClassName="txn-section-table"
                  />
                ) : (
                  <>
                    <div className="text-grey-text flex flex-col justify-center items-center space-y-3 h-full">
                      <SearchIcon className="stroke-current" />
                      <span>Search for merchants by business name</span>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {modalVisible && (
        <FilterModal
          active={modalVisible}
          onClose={() => setModalVisible(false)}
          setFilterData={setFilterInput}
          filterData={filterInput}
          handleFilter={handleFilter}
          clearFilters={clearFilters}
          modalType={SEARCH_FILTER}
        />
      )}
    </>
  );
}
