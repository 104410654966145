import { all } from "redux-saga/effects";
import { authSaga } from "./auth/saga/saga";
import { miscSaga } from "./misc/saga";
import { signInSaga } from "pages/OnBoarding/SignIn/slice/saga";
import { transactionsSaga } from "pages/Dashboard/Transactions/slice/saga";
import complianceSaga from "pages/Dashboard/Compliance/slice/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    signInSaga(),
    miscSaga(),
    transactionsSaga(),
    complianceSaga(),
  ]);
}
