import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "components/General/Button/Button";
import Modal from "components/General/Modal/Modal/Modal";
import Select from "components/General/Input/Select";
import ModalBody from "components/General/Modal/ModalBody/ModalBody";
import ModalHeader from "components/General/Modal/ModalHeader/ModalHeader";
import ModalFooter from "components/General/Modal/ModalFooter/ModalFooter";
import DatePicker from "components/General/DatePicker";
import { hasValue } from "utils/validations";
import {
  paymentMethods,
  transactionTypes,
  statusType,
} from "utils/transactions";
import { MERCHANTS_MODAL_TYPES } from "utils/appConstant";

const { SEARCH_FILTER, MECHANT_TRANSACTIONS_FILTER } = MERCHANTS_MODAL_TYPES;
export default function FilterModal({
  onClose,
  active,
  setFilterData,
  filterData,
  handleFilter,
  clearFilters,
  modalType,
}) {
  const handleChangeFilter = (name, value) => {
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  let invalid = true;
  if (hasValue(filterData.start_date) && !hasValue(filterData.end_date)) {
    invalid = true;
  } else if (
    !hasValue(filterData.start_date) &&
    hasValue(filterData.end_date)
  ) {
    invalid = true;
  } else {
    invalid = false;
  }

  return (
    <div>
      <Modal size="md" active={active} toggler={onClose}>
        <ModalHeader>
          <h3 className="text-center text-[16px]"> Filters</h3>
        </ModalHeader>
        <ModalBody>
          <div className="space-y-[24px] pt-4 pb-6 w-full">
            <div className="flex flex-col justify-start items-start space-y-2 w-full">
              <DatePicker
                label="Start date"
                placeholder="Start date"
                name="start_date"
                value={
                  moment(filterData.start_date).isValid()
                    ? moment(filterData.start_date)._d
                    : ""
                }
                maxDate={
                  moment(filterData.end_date).isValid()
                    ? moment(filterData.end_date).subtract(1, "days")._d
                    : moment().subtract(1, "days")._d
                }
                dateFormat="dd MMMM yyyy"
                onChange={(value) =>
                  handleChangeFilter(
                    "start_date",
                    moment(value).format("YYYY-MM-DD")
                  )
                }
              />
            </div>

            <div className="flex flex-col justify-start items-start space-y-2 w-full">
              <DatePicker
                label="End date"
                placeholder="End date"
                value={
                  moment(filterData.end_date).isValid()
                    ? moment(filterData.end_date)._d
                    : ""
                }
                name="end_date"
                dateFormat="dd MMMM yyyy"
                minDate={
                  moment(filterData.start_date).isValid()
                    ? moment(filterData.start_date).add(1, "days")._d
                    : ""
                }
                onChange={(value) =>
                  handleChangeFilter(
                    "end_date",
                    moment(value).format("YYYY-MM-DD")
                  )
                }
              />
            </div>
            {modalType === SEARCH_FILTER && (
              <div className="flex flex-col justify-start items-start space-y-2 w-full">
                <Select
                  value={filterData.account_status}
                  placeholder="Select Account Status"
                  name="account_status"
                  label="Account Status"
                  options={statusType}
                  onChange={(value) =>
                    handleChangeFilter("account_status", value)
                  }
                />
              </div>
            )}
            {modalType === MECHANT_TRANSACTIONS_FILTER && (
              <>
                <div className="flex flex-col justify-start items-start space-y-2 w-full">
                  <Select
                    value={filterData.cur_type}
                    placeholder="Select transaction type"
                    name="cur_type"
                    label="Transaction type"
                    options={paymentMethods}
                    onChange={(value) => handleChangeFilter("cur_type", value)}
                  />
                </div>

                <div className="flex flex-col justify-start items-start space-y-2 w-full">
                  <Select
                    value={filterData.tx_type}
                    placeholder="Select category"
                    name="tx_type"
                    label="Payment method"
                    options={transactionTypes}
                    onChange={(value) => handleChangeFilter("tx_type", value)}
                  />
                </div>
              </>
            )}
          </div>
        </ModalBody>

        <ModalFooter>
          <div className="flex justify-between items-center w-full ">
            <div>
              <Button whiteBg text="Clear" onClick={clearFilters} />
            </div>
            <div>
              <Button
                type="submit"
                text="Apply"
                onClick={handleFilter}
                isDisabled={invalid}
              />
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

FilterModal.propTypes = {
  onClose: PropTypes.func,
  active: PropTypes.bool,
  setFilterData: PropTypes.func,
  filterData: PropTypes.object,
  handleFilter: PropTypes.func,
  clearFilters: PropTypes.func,
  modalType: PropTypes.oneOf([SEARCH_FILTER, MECHANT_TRANSACTIONS_FILTER]),
};
