import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { pageCount } from "utils/appConstant";
import {
  determineTransactionType,
  transactionAmount,
} from "utils/transactions";
import CircleLoader from "components/General/CircleLoader/CircleLoader";
import Table from "components/General/Table";
import useWindowDimensions from "hooks/useWindowDimensions";
import { PaymentMethod } from "styles/transactions";
import { getCellContent } from "utils/functions";
import transactionsSlice from "../slice";
import { numberWithCommas } from "utils/formatter";
import { hasValue } from "utils/validations";
import SearchBar from "components/General/Searchbar/SearchBar";

const { actions } = transactionsSlice;
const RecentTransactions = ({ mobile, tribe_account_id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { transactions, loading, merchantDetails } = useSelector(
    (state) => state.transactions
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const searchQuery = searchInput?.trim();

  useEffect(() => {
    if (tribe_account_id) {
      fetchMerchantsTransactions();
    }
  }, [currentPage]);

  useEffect(() => {
    if (searchQuery?.length > 1) {
      dispatch(
        actions.searchMerchantTransactions({
          payload: {
            tribe_account_id,
            ...(hasValue(searchQuery) && {
              tx_keyword: searchQuery,
            }),
          },
        })
      );
    } else if (searchQuery === "") fetchMerchantsTransactions();
  }, [searchInput]);

  const fetchMerchantsTransactions = () => {
    dispatch(
      actions.getMerchantTransactions({
        payload: {
          tribe_account_id,
          start_date: "2022-01-01",
          end_date: moment(new Date()).format("YYYY-MM-DD"),
        },
        page: currentPage,
      })
    );
  };

  const columns = [
    {
      name: "Type",
      selector: (row) => getCellContent(determineTransactionType(row)),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => getCellContent(transactionAmount(row)),
      sortable: true,
    },
    {
      name: "Payment Method",
      selector: (row) => (
        <PaymentMethod>
          {row?.tx_wallet_details?.tx_type.replace("_", " ")}
        </PaymentMethod>
      ),
      sortable: true,
    },
    {
      name: "Reference",
      selector: "transaction_reference",
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) =>
        getCellContent(moment(row.pub_date).format("DD MMM YYYY")),
      sortable: true,
    },
  ];

  const viewTransactionDetails = ({ transaction_reference }) => {
    navigate(
      `/dashboard/merchants/transactions/${merchantDetails?.account_tribe_ref}/${transaction_reference}`
    );
  };
  return (
    <div
      className={`bg-white flex flex-col justify-start items-center w-full h-fit rounded-lg mb-5 border-1/2 border-grey-bordercolor`}
    >
      <div className="flex justify-between items-center w-full px-4 py-6 border-b-1/2 border-grey-border">
        <h3 className="text-lg">
          Transactions - {numberWithCommas(transactions?.count || 0)}
        </h3>
        <div className="w-[40%]">
          <SearchBar
            placeholder="Search for transaction"
            onChange={setSearchInput}
            value={searchInput}
            isLoading={loading}
          />
        </div>
      </div>

      {loading ? (
        <div className="flex flex-grow justify-center items-center h-full w-full p-6">
          <CircleLoader blue />
        </div>
      ) : transactions?.data?.length > 0 ? (
        <Table
          data={transactions?.data}
          columns={width >= 640 ? columns : columns.slice(0, 2)}
          pointerOnHover
          isLoading={loading}
          pageCount={transactions?.count / pageCount}
          onPageChange={(page) => setCurrentPage(page)}
          currentPage={currentPage}
          onRowClicked={viewTransactionDetails}
          tableClassName="txn-section-table"
        />
      ) : (
        <>
          <div className="text-grey-text h-full flex flex-col justify-center items-center space-y-4 p-4">
            {/* <BusinessIcon className="stroke-current " /> */}
            <span className="text-sm">There are no transactions currently</span>
          </div>{" "}
        </>
      )}
    </div>
  );
};
RecentTransactions.propTypes = {
  mobile: PropTypes.bool,
  tribe_account_id: PropTypes.string,
};
export default RecentTransactions;
