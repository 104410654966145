import { Route, Routes } from "react-router-dom";

import Transactions from "pages/Dashboard/Transactions";
import Shoppers from "pages/Dashboard/Shoppers";
import MerchantsList from "pages/Dashboard/Transactions/features/MerchantsList";
import ShoppersList from "pages/Dashboard/Shoppers/features/MerchantsList";
import MerchantProfile from "pages/Dashboard/Transactions/features/MerchantProfile";
import ShoppersProfile from "pages/Dashboard/Shoppers/features/MerchantProfile";
import TransactionsDetails from "pages/Dashboard/Transactions/features/TransactionDetails";
import SignIn from "pages/OnBoarding/SignIn";
import ComplianceList from "pages/Dashboard/Compliance/features/ComplianceList";
import Compliance from "pages/Dashboard/Compliance";
import AccountHolderForm from "pages/Dashboard/Compliance/features/AccountHolderForm";
import BusinessAccountForm from "pages/Dashboard/Compliance/features/BusinessAccountForm";
import BankVerificationForm from "pages/Dashboard/Compliance/features/BankVerificationForm";
import { ProtectedRoute } from "components/ProtectedRoute";

const Router = () => {
  return (
    <>
      <Routes>
        {/* transactions */}
        <Route
          path="/dashboard/merchants"
          element={
            <ProtectedRoute path="">
              <Transactions />
            </ProtectedRoute>
          }
        >
          <Route path="" element={<MerchantsList />} />
          <Route path=":tribe_account_id" element={<MerchantProfile />} />
          <Route
            path="transactions/:account_tribe_ref/:transaction_id"
            element={<TransactionsDetails />}
          />
        </Route>
        {/* end transactions */}
        <Route
          path="/dashboard/shoppers"
          element={
            <ProtectedRoute path="">
              <Shoppers />
            </ProtectedRoute>
          }
        >
          <Route path="" element={<ShoppersList />} />
          <Route path=":tribe_account_id" element={<ShoppersProfile />} />
          <Route
            path="transactions/:account_tribe_ref/:transaction_id"
            element={<TransactionsDetails />}
          />
        </Route>

        {/* compliance */}
        <Route
          path="/dashboard/compliance/:tribe_account_id"
          element={
            <ProtectedRoute path="">
              <Compliance />
            </ProtectedRoute>
          }
        >
          <Route path="" element={<ComplianceList />} />
          <Route path="holder" element={<AccountHolderForm />} />
          <Route path="business" element={<BusinessAccountForm />} />
          <Route path="bvn" element={<BankVerificationForm />} />
        </Route>
        {/* end compliance */}

        <Route
          path="/"
          element={
            <ProtectedRoute path="/" notProtected>
              <SignIn />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

export default Router;
