import { call, put, takeLatest } from "redux-saga/effects";

import apis from "services/user";
import signInSlice from ".";

const { actions } = signInSlice;

export function* signInUser(action) {
  try {
    const response = yield call(apis.loginUser, action.payload);
    if (response.otp_type) {
      const otpDetails = { otp_type: response.otp_type };
      if (response.otp_email) {
        otpDetails.otp_email = response.otp_email;
      }
      yield put(actions.requireOtp(otpDetails));
    } else {
      yield put(actions.signInUserSuccess(response));
    }
  } catch ({ response }) {
    const { data } = response;
    yield put(actions.signInUserError(data));
  }
}

export function* verifyOtp(action) {
  try {
    const response = yield apis.verifyOTP(action.payload);
    yield put(actions.signInUserSuccess(response));
  } catch (e) {
    yield put(actions.verifyOTPError());
  }
}

export function* signInSaga() {
  yield takeLatest(actions.signInUser.type, signInUser);
  yield takeLatest(actions.verifyOtp.type, verifyOtp);
}
