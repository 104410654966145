import { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";

import Toast from "components/General/Toast/Toast";
import Routes from "./routes";
import { useAuth } from "./hooks/auth";
import "./App.css";

function App() {
  const { initUserSession } = useAuth();
  const { theme } = useSelector((state) => ({
    theme: state.globals.theme,
  }));

  useEffect(() => {
    initUserSession();
  }, []);

  return (
    <div className="App">
      <Toast />
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
