import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "classnames";
import { ReactComponent as CancelIcon } from "assets/icons/cancel.svg";
import { hideSideNav, showSideNav } from "utils/functions";

const Modal = ({
  active,
  toggler,
  size,
  children,
  noPadding,
  className,
  hasToggler = true,
  maxHeight = "670px",
  containerClassName = "overflow-y-auto",
  modalClassName,
}) => {
  const modalClassNames = {
    "w-full md:max-w-[86%] md:max-h-[89%] md:w-[86%] md:h-[89%]": size === "xl",
    "w-full md:max-w-lg": size === "lg",
    "w-full md:max-w-md": size === "md",
    "w-full md:max-w-sm": size === "sm",
    "w-full md:w-fit": !size,
    "opacity-100 translate-y-0 bottom-0 pointer-events-auto": active,
    "md:translate-y-1/4 translate-y-[1000px] opacity-0 pointer-events-none bottom-0":
      !active,
    "p-[32px]": !noPadding,
    [className]: className,
  };

  useEffect(() => {
    if (active) {
      hideSideNav();
    } else {
      showSideNav();
    }
  }, [active]);

  return (
    <div
      className={`h-screen overflow-hidden w-full fixed py-8 !m-0 flex justify-center items-start backdrop z-[9998] bottom-0 md:top-0 left-0
      ${containerClassName}
    ${
      active
        ? "transition-all duration-100 ease-in-out opacity-100 pointer-events-auto"
        : "transition-all duration-300 ease-in-out opacity-0 !pointer-events-none"
    }
    `}
    >
      <div
        style={{ maxHeight }}
        className={clsx(
          `!absolute md:!relative modal-content-area md:max-h-[670px] flex flex-col justify-start bg-white mt-4 rounded-bl-none rounded-br-none md:rounded-bl-lg md:rounded-br-lg rounded-lg  w-full transition-all z-[900] duration-300 ease-in-out`,
          { ...modalClassNames },
          modalClassName
        )}
      >
        {children}
        {hasToggler && (
          <div
            className="absolute md:top-0 top-[-60px]  toggler right-[16.33px] md:-right-14 cursor-pointer flex justify-center items-center text-white bg-grey-whitesmoke bg-opacity-30 hover:bg-opacity-100 hover:text-black hover:bg-white rounded-full transition-all duration-150 ease-in-out "
            onClick={toggler}
          >
            <div className="h-8 w-8 relative flex justify-center items-center">
              <CancelIcon className="stroke-current" />
            </div>
          </div>
        )}
      </div>

      <div className="fixed top-0 left-0 h-screen w-full !my-0 "></div>
    </div>
  );
};

Modal.propTypes = {
  active: PropTypes.bool,
  toggler: PropTypes.func,
  size: PropTypes.string,
  children: PropTypes.elementType,
  noPadding: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  hasToggler: PropTypes.bool,
  maxHeight: PropTypes.string,
  modalClassName: PropTypes.string,
};

export default Modal;
