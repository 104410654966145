import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import DashboardLayout from "components/Layout/DashboardLayout";
import { handleSetComplianceStatuses } from "utils/compliance";
import complianceSlice from "./slice";
import transactionsSlice from "pages/Dashboard/Transactions/slice";

const { actions } = complianceSlice;
const { actions: transactionsAction } = transactionsSlice;

const Compliance = () => {
  // const navigate = useNavigate();

  const dispatch = useDispatch();
  const params = useParams();
  // const { currentBusiness } = useSelector((state) => state.auth);
  const { compliance, complianceStatuses } = useSelector(
    (state) => state.compliance
  );

  const { merchantDetails: profileData } = useSelector(
    (state) => state.transactions
  );

  const { tribe_account_id } = params || {};

  // useEffect(() => {
  //   if (businessIsApproved) {
  //     navigate("/dashboard/overview", { replace: true });
  //   }
  // }, [businessIsApproved]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    handleSetStatuses(compliance);
  }, [compliance, profileData]);

  const fetchData = () => {
    if (tribe_account_id) {
      dispatch(
        actions.fetchAccountCompliance({
          tribe_account_id,
        })
      );
      dispatch(transactionsAction.getMerchantDetails({ tribe_account_id }));
    }
  };

  const handleSetStatuses = (complianceData) => {
    const stats = handleSetComplianceStatuses(complianceData, profileData);
    // setting user status
    // const allUserStatuses = [stats.videoStatus, stats.idStatus];
    const allUserStatuses = [stats.idStatus];
    const acceptedUserStatuses = allUserStatuses.filter(
      (stat) => stat === "accepted" || stat === "approved"
    );
    const userStats = allUserStatuses.includes("rejected")
      ? "rejected"
      : allUserStatuses.includes("pending")
      ? "pending"
      : acceptedUserStatuses.length === allUserStatuses.length
      ? "accepted"
      : "";

    // setting business status
    const allBusinessStatuses = [
      stats.utilityStatus,
      stats.rcStatus,
      stats.tinNoStatus,
      stats.regCertificateStatus,
    ];

    const acceptedBusinessStatuses = allBusinessStatuses.filter(
      (stat) => stat === "accepted" || stat === "approved"
    );

    const businessStats = allBusinessStatuses.includes("rejected")
      ? "rejected"
      : allBusinessStatuses.includes("pending")
      ? "pending"
      : acceptedBusinessStatuses.length === allBusinessStatuses.length
      ? "accepted"
      : "";
    dispatch(
      actions.setComplianceStatuses({
        ...complianceStatuses,
        userStats,
        businessStats,
        tribe_account_id,
        stats,
      })
    );
  };

  return (
    <div>
      <Helmet>
        <title>Compliance - Bani</title>
      </Helmet>
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </div>
  );
};

export default Compliance;
