// import {
//   FLUSH,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
//   REHYDRATE,
//   persistReducer,
//   persistStore,
// } from "redux-persist";

// import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "./rootReducer";
// import storage from "redux-persist/lib/storage";

// const persistConfig = {
//   key: "root",
//   version: 1,
//   storage,
//   blacklist: ["error"],
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // const store = configureStore({
// //   reducer: {
// //     auth: authReducer,
// //     init: initReducer,
// //     reg: registrationReducer,
// //   },
// //   middleware: (getDefaultMiddleware) =>
// //     getDefaultMiddleware({ thunk: false }).concat(sagaMiddleWare),
// // });

// // Store
// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       thunk: false,
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// });

// export const persistor = persistStore(store);

// export default store;

import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import sagas from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

// then run the saga
sagaMiddleware.run(sagas);

export default store;
