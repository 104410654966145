import React, { useEffect } from "react";
import clsx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router";
import DetailsBlock from "components/General/SectionDetails/DetailsBlock";
import CircleLoader from "components/General/CircleLoader/CircleLoader";
import {
  determineSourceAccount,
  determineTransactionStatus,
  determineTransactionType,
  determineTxnFrom,
  transactionAmount,
} from "utils/transactions";
import { numberWithCommas } from "utils/formatter";
import useDefaultCurrency from "hooks/defaultCurrency";

import BreadCrumbs from "components/General/BreadCrumbs";
import transactionsSlice from "../slice";
import { TransactionDetailsWrapper } from "../style";

export default function TransactionDetails() {
  const { transactionDetails, loading } = useSelector(
    (state) => state.transactions
  );
  const { transaction_id, account_tribe_ref } = useParams();

  const { actions } = transactionsSlice;
  const dispatch = useDispatch();
  const { currency } = useDefaultCurrency();

  useEffect(() => {
    if (transaction_id && account_tribe_ref) {
      dispatch(
        actions.getTransactionDetails({
          transaction_id,
          tribeRef: account_tribe_ref,
        })
      );
    }
  }, [transaction_id, account_tribe_ref]);

  const txnStatus =
    transactionDetails?.payout_details?.payout_status ||
    transactionDetails?.payin_details?.pay_status ||
    transactionDetails?.fiat_payin_details?.pay_status ||
    transactionDetails?.fiat_payout_details?.payout_status ||
    transactionDetails?.crypto_payin_details?.transaction_status ||
    transactionDetails?.crypto_payout_details?.transaction_status ||
    transactionDetails?.exchange_details?.exchange_status ||
    "";

  const payoutTxnStatus =
    transactionDetails?.payout_details?.payout_status ||
    transactionDetails?.fiat_payout_details?.payout_status ||
    transactionDetails?.crypto_payout_details?.transaction_status ||
    "";

  const transactionSummary = {
    "Transaction reference":
      transactionDetails?.crypto_payin_details?.payment_ref ||
      transactionDetails?.crypto_payout_details?.payment_ref ||
      transactionDetails?.transaction_reference,
    payment_method: transactionDetails?.exchange_details
      ? transactionDetails.exchange_details.exchange_type
      : transactionDetails?.fiat_payin_details?.pay_method.replace("_", " ") ||
        transactionDetails?.payout_details?.payout_method.replace("_", " ") ||
        (transactionDetails?.crypto_payin_details && "Crypto") ||
        (transactionDetails?.crypto_payout_details && "Crypto") ||
        "",
    From: determineTxnFrom(transactionDetails),
    "Date/Time": moment(transactionDetails?.pub_date).format(
      "DD MMM, YYYY HH:mm A"
    ),
    "Coin amount collected":
      determineTransactionStatus(
        txnStatus,

        payoutTxnStatus
      ) === "part paid"
        ? transactionDetails?.crypto_payin_details?.coin_amount_collected ||
          transactionDetails?.crypto_payout_details?.coin_amount_collected
        : "",
    Status: determineTransactionStatus(txnStatus, payoutTxnStatus),

    "Order total amount":
      determineTransactionStatus(txnStatus, payoutTxnStatus) === "part paid"
        ? transactionDetails?.payin_details?.merch_amount &&
          transactionDetails?.payin_details?.merch_currency +
            " " +
            transactionDetails?.payin_details?.merch_amount
        : "",

    "Balance after":
      transactionDetails?.balance_fiat_after > 0
        ? `${
            currency || transactionDetails?.tx_wallet_details?.currency
          } ${numberWithCommas(transactionDetails?.balance_fiat_after)}`
        : transactionDetails?.balance_coin_after,
    account_name: determineSourceAccount(transactionDetails),
    account_num:
      transactionDetails?.payout_details?.receiver_account_num ||
      transactionDetails?.payout_details?.receiver_phone ||
      transactionDetails?.payin_details?.holder_account_number ||
      transactionDetails?.payin_details?.holder_phone ||
      "",
    "Fiat amount": transactionDetails?.crypto_payin_details?.merch_amount
      ? transactionDetails?.crypto_payin_details?.merch_currency +
        transactionDetails?.crypto_payin_details?.merch_amount
      : transactionDetails?.crypto_payout_details?.merch_amount
      ? transactionDetails?.crypto_payout_details?.merch_currency +
        transactionDetails?.crypto_payout_details?.merch_amount
      : "",
    bank_name_phone_carrier:
      transactionDetails?.payout_details?.receiver_bank_name ||
      transactionDetails?.payin_details?.holder_bank_name ||
      transactionDetails?.payin_details?.holder_phone_carrier ||
      "",
    "Transaction hash":
      transactionDetails?.crypto_payin_details?.tx_hash ||
      transactionDetails?.crypto_payout_details?.tx_hash ||
      "",
    "Transaction note": transactionDetails?.transaction_note,
    // "Order reference": (
    //   <span className="highlight-text">28289127182162718</span>
    // ),
  };

  return (
    <div className="">
      <BreadCrumbs
        className=""
        links={[
          { name: "Merchants", link: "/dashboard/merchants" },
          { name: "Merchant Account", link: -1 },
          { name: "Transaction Details" },
        ]}
      />

      <div className="flex flex-grow justify-center items-center h-full w-full pt-4 sm:pt-0">
        {loading ? (
          <div className="flex flex-grow justify-center items-center h-full w-full pt-6 sm:pt-0">
            <CircleLoader blue />
          </div>
        ) : (
          <TransactionDetailsWrapper className="transaction-details ">
            <div
              className={clsx("trx-type", {
                debit:
                  determineTransactionType(transactionDetails, true) ===
                  "Debit",
              })}
            >
              <h6>{determineTransactionType(transactionDetails, true)}</h6>
            </div>
            <div className="transaction-main-content">
              <div className="trx-amount-container">
                <p className="amount-text">Amount</p>
                <h3 className="amount uppercase">
                  {transactionAmount(transactionDetails)}
                </h3>
              </div>

              <div className="transaction-summary space-y-[32px]">
                {Object.keys(transactionSummary).map((item) => (
                  <DetailsBlock
                    title={item}
                    value={transactionSummary[item]}
                    key={item}
                    transactionDetails={transactionDetails}
                  />
                ))}
              </div>
            </div>
          </TransactionDetailsWrapper>
        )}
      </div>
    </div>
  );
}
