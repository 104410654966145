import { apiInstance2 } from "../utils/apiInstance";

const apis = {
  businessCategories: (data) =>
    apiInstance2("/misc/list_all_categories/", {
      body: data,
    }),

  fetchUserIp: (data) =>
    apiInstance2("/partner/catch_user_adr/", {
      body: data,
    }),
  fetchCountryBanks: ({ countryCode }) =>
    apiInstance2(`/partner/list_payment_banks/${countryCode}/`),
};

export default apis;
