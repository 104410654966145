import { createSlice } from "@reduxjs/toolkit";
import { saveCurrentBusiness } from "utils/storage";

const initialState = {
  isSignedIn: false,
  userDetails: null,
  accountDetails: null,
  currentUser: {},
  businesses: [],
  currentBusiness: {},
  fetchingBusinesses: false,
  logingOut: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userSignIn: (state, action) => {
      return {
        ...state,
        isSignedIn: true,
        userDetails: action.payload,
      };
    },
    setAccountDetails: (state, action) => {
      return {
        ...state,
        accountDetails: action.payload,
      };
    },
    setCurrentUser: (state, action) => {
      return {
        ...state,
        currentUser: action.payload,
        isSignedIn: Object.values(action.payload).length > 0,
      };
    },
    setUserBusinesses: (state, action) => {
      return {
        ...state,
        businesses: action.payload,
      };
    },
    setCurrentBusiness: (state, action) => {
      saveCurrentBusiness(action?.payload?.tribe_account_id);
      return {
        ...state,
        currentBusiness: action.payload,
      };
    },
    fetchBusinesses: (state, action) => {
      state.fetchingBusinesses = true;
    },
    fetchBusinessesSuccess: (state, action) => {
      state.fetchingBusinesses = false;
      state.businesses = action.payload;
    },
    fetchBusinessesEnd: (state) => {
      state.fetchingBusinesses = false;
    },
    logout: () => {},
    logoutSuccess: () => {},
    logoutError: () => {},
  },
});

export const {
  userSignIn,
  setAccountDetails,
  setCurrentUser,
  setUserBusinesses,
  setCurrentBusiness,
  fetchBusinesses,
  fetchBusinessesSuccess,
  logout,
  logoutSuccess,
  logoutError,
} = authSlice.actions;

export default authSlice;
