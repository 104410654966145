/* eslint-disable dot-notation */
import axios from "axios";
import { lowerCase } from "lodash";
import { getToken } from "../utils/storage";
import { errorToast } from "components/General/Toast/Toast";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

function logout() {
  window.localStorage.clear();
}

const ERROR_TOAST_MESSAGE_EXCEPTIONS = [
  "no keys found for this account",
  "invalid token",
];
const ERROR_TOAST_SEARCH_MESSAGE_EXCEPTION = "invalid page.";
export function apiInstance2(
  endpoint,
  { method = "GET", data, body, ...customConfig } = {}
) {
  const token = getToken();
  let url = "";

  const headers = { "content-type": "application/json" };

  if (token) {
    headers.Authorization = `Token ${token}`;
  }

  const config = {
    method,
    data: data || body,
    ...customConfig,
    headers: {
      ...(token && { Authorization: token }),
      ...headers,
      ...customConfig.headers,
    },
  };

  if (!REACT_APP_BASE_URL) {
    url = endpoint;
  } else {
    url = `${REACT_APP_BASE_URL}${endpoint}`;
  }

  return axios(url, config)
    .then(async (response) => {
      const data = response.data;
      if (response.statusText) {
        return Promise.resolve({
          ok: response.statusText,
          ...data,
        });
      }
    })
    .catch(async (_err) => {
      let message;
      const errStatus = _err?.response?.status;
      switch (errStatus) {
        case 400:
          message = "Bad Request";
          break;
        case 401:
          logout();
          window.location.assign(window.location);
          message = "You're not Authenticated. Kindly Login";
          break;
        case 403:
          message = "Unauthorised User";
          errorToast(
            "Unauthorised Action",
            "Sorry, you are not permitted to perform this action."
          );
          break;
        case 404:
          message = "Resource not found";
          break;
        case 500:
          message = "Internal server error";
          break;
        default:
          message = "";
      }

      if (
        _err?.response?.data &&
        !ERROR_TOAST_MESSAGE_EXCEPTIONS.includes(
          lowerCase(_err.response.data?.message)
        ) &&
        errStatus !== 403
      ) {
        lowerCase(ERROR_TOAST_SEARCH_MESSAGE_EXCEPTION) !==
          lowerCase(_err?.response?.data?.message) &&
          errorToast(
            "Error",
            _err.response.data.message
              ? _err.response.data.message
              : "An error occured"
          );
      }

      const errorRes = {
        response: {
          ok: _err?.response?.statusText,
          custom_message: message,
          data: _err?.response?.data,
        },
      };
      await Promise.reject(errorRes);
    });
}

export const apiInstance = axios.create({
  baseURL: REACT_APP_BASE_URL,
  withCredentials: true,
});

export const setToken = (token) => {
  apiInstance.defaults.headers["Authorization"] = `Token ${token}`;
  apiInstance.defaults.withCredentials = false;
};
