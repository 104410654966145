import { createSlice } from "@reduxjs/toolkit";
import theme from "themes";

const { defaultColors, mediaQueries } = theme;

const initialState = {
  theme: {
    colors: defaultColors,
    mediaQueries,
  },
};

export const globalSlice = createSlice({
  name: "globals",
  initialState,
  reducers: {
    setDefaultTheme: (state) => {
      state.theme.colors = defaultColors;
    },
  },
});

export default globalSlice;
