import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Loader } from "assets/icons/Loader/Loader.svg";
import clsx from "classnames";

const Button = ({
  type,
  isOutline,
  text,
  isDisabled,
  onClick,
  icon,
  isLoading,
  whiteBg,
  innerClassName,
  redBg,
  fullWidth,
  borderColor,
  textColor,
  textClass = "helv-medium",
  className,
  rightIcon,
}) => {
  return (
    <button
      type={type ?? "button"}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      className={clsx("relative", className, { "w-full": fullWidth })}
    >
      <div
        className={`
        flex justify-center items-center
        h-[44px] outline-none text-sm
        rounded-lg font-helvetica p-4
        bani-button
        transition-all duration-150 ease-in-out
        ${fullWidth ? "w-full" : ""}
        whitespace-nowrap ${
          isOutline
            ? `bg-white ${
                isDisabled ? "bg-white/[.2]" : ""
              } text-blue border-1/2 border-blue`
            : whiteBg
            ? `bg-white ${isDisabled ? "bg-white/[.2]" : ""} text-${
                textColor || "grey-text"
              } border-1/2 border-${borderColor || "grey-border"}`
            : redBg
            ? `bg-red ${isDisabled ? "bg-red/[.2]" : ""} text-white`
            : ` ${
                isDisabled ? "bg-blue/[.2]" : "bg-blue hover:bg-blue-hover"
              } text-white`
        }  ${innerClassName}`}
      >
        {isLoading && (
          <div
            className={
              "absolute left-0 top-0 h-full w-full flex items-center justify-center"
            }
          >
            <Loader />
          </div>
        )}

        <div className={clsx("flex items-center", { "opacity-0": isLoading })}>
          {icon && <div className="mr-4">{icon}</div>}
          <p className={`bani-button-text font-helvetica-neue ${textClass}`}>
            {text}
          </p>
          {rightIcon && <div className="ml-2">{rightIcon}</div>}
        </div>
      </div>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.any,
  isOutline: PropTypes.bool,
  text: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  whiteBg: PropTypes.bool,
  redBg: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.element,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  textClass: PropTypes.string,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  rightIcon: PropTypes.element,
};

export default Button;
