import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useLocation } from "react-router";
import { ReactComponent as Logo } from "assets/logos/faint-black.svg";
import { ReactComponent as CaretUp } from "assets/icons/caret-up-white.svg";
import Loading from "components/General/CircleLoader/CircleLoader";
import HeadwayWidget from "components/General/HeadwayWidget";
import { IS_DEV } from "utils/appConstant";

import Toast from "../General/Toast/Toast";
import Hamburger from "./Components/hamburger";
import SideNav from "./Components/SideNav";
import BusinessDropdown from "./HeaderSearch";

const DashboardLayout = ({ children }) => {
  const topRef = useRef(null);
  const childrenContainerRef = useRef(null);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const { fetchingBusinesses } = useSelector((state) => state.auth);

  const location = useLocation();

  const layoutLoading = fetchingBusinesses;

  const handleScrollTop = () => {
    if (window.scrollY >= 150) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  };

  useEffect(() => {
    handleScrollTop();
    window.addEventListener("scroll", handleScrollTop);
    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, []);

  const scrollToTop = () => {
    topRef.current.scrollIntoView();
  };

  return (
    <div
      className={`w-screen flex flex-grow flex-col h-full ${
        IS_DEV ? "h-with-test-banner" : "min-h-[100vh]"
      }`}
    >
      <Toast />

      <header className="dashboard-header px-[12px] flex flex-row justify-between items-center w-full border-b border-grey-border h-[49px] md:h-20 ">
        <div className="relative flex flex-row justify-between items-center mx-auto w-full md:px-6 ">
          <Hamburger
            click={() => {
              setSidenavOpen(!sidenavOpen);
            }}
            className={sidenavOpen ? "ham_crossed" : ""}
          />
          <div className="h-8 w-16 !my-0">
            <Logo className="w-full h-full" />
          </div>

          <div className="flex flex-row justify-start items-center space-x-[20px]">
            {location.pathname !== "/dashboard/merchants" &&
              location.pathname !== "/dashboard/shoppers" && (
                <BusinessDropdown />
              )}

            <HeadwayWidget />
          </div>
        </div>
      </header>

      <section className="w-full h-full flex flex-row flex-grow max-w-9xl mx-auto">
        <SideNav
          {...{
            sidenavOpen,
            setSidenavOpen,
          }}
        />
        <div className="lg:hidden">
          <SideNav
            withBackDrop
            {...{
              sidenavOpen,
              setSidenavOpen,
            }}
          />
        </div>
        <main
          className={classNames(
            "mt-[49px] md:mt-[80px] w-full lg:ml-[200px] bg-grey-whitesmoke pb-14 px-6 pt-6 flex flex-col flex-grow overflow-y-scroll overflow-x-hidden relative "
          )}
          ref={childrenContainerRef}
        >
          <div ref={topRef} />

          {layoutLoading ? (
            <div className="flex items-center justify-center h-full w-full">
              <Loading />
            </div>
          ) : (
            children
          )}
        </main>
      </section>

      <button
        onClick={scrollToTop}
        className={classNames(
          "fixed cursor-pointer h-[65.33px] flex items-center justify-center w-[65.33px] rounded-[50%] bg-blue bottom-[130px] right-[10.67px] transition-all duration-500 ease",
          {
            "z-[-9] opacity-0 ": !showScrollTop,
            "z-[200] opacity-100": showScrollTop,
          }
        )}
      >
        <CaretUp className="stroke-white" />
      </button>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default DashboardLayout;
