import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: null,
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    messageTrue: (state, action) => {
      return {
        ...state,
        message: {
          header: action.payload.header,
          body: action.payload.body,
        },
      };
    },
    messageFalse: (state, action) => {
      return {
        ...state,
        message: null,
      };
    },
  },
});

export const { messageFalse, messageTrue } = messageSlice.actions;

export default messageSlice.reducer;
