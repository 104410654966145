import { call, takeLatest, put } from "redux-saga/effects";

import apis from "services/compliance";
import productSlice from ".";
const { actions } = productSlice;
function* getAccountCompliance(action) {
  try {
    const { data } = yield call(apis.getAccountCompliance, action.payload);
    yield put(actions.fetchAccountComplianceSuccess(data));
  } catch ({ response }) {
    const data = response?.data || "There was an error";
    yield put(actions.fetchAccountComplianceError(data));
  }
}

export default function* complianceSaga() {
  yield takeLatest(actions.fetchAccountCompliance, getAccountCompliance);
}
