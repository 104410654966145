import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as WarningWhite } from "assets/icons/ErrorMessage/warning-white.svg";
import { ReactComponent as Success } from "assets/icons/Toast/success.svg";
import cleanPayload from "utils/cleanPayload";
import { handleFileType } from "utils/functions";
import { handleSetComplianceStatuses } from "utils/compliance";
import useWindowDimensions from "hooks/useWindowDimensions";
import Button from "components/General/Button/Button";
import FileInput from "components/General/Input/FileInput";
import businessSlice from "../slice";
import ImageModal from "./ImageModal";

const { actions } = businessSlice;

const AccountHolderForm = () => {
  const errorContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { currentBusiness } = useSelector((state) => state.auth);
  const { merchantDetails: profileData } = useSelector(
    (state) => state.transactions
  );
  const { complianceStatuses, compliance } = useSelector(
    (state) => state.compliance
  );

  const [convertingImage, setConvertingImage] = useState({});

  const emptyBusiness = {
    identity_photo: profileData?.account_holder_details?.identity_photo || "",
    account_video: currentBusiness?.account_rc_number || "",
  };
  const emptyFiles = {
    identity_photo: { type: "", url: "" },
  };
  const emptyImageModal = {
    show: false,
    type: "",
  };

  const [business, setBusiness] = useState({
    ...emptyBusiness,
  });
  const [files, setFiles] = useState({ ...emptyFiles });
  const [imageModal, setImageModal] = useState({ ...emptyImageModal });

  const { tribe_account_id } = currentBusiness;

  useEffect(() => {
    setBusiness({ ...emptyBusiness });
  }, [profileData, currentBusiness]);

  useEffect(() => {
    handleFiles();
  }, [business.identity_photo, currentBusiness]);
  const handleFiles = () => {
    const identity_photo = handleFileType(
      business?.identity_photo,
      "identity_photo"
    );

    setFiles({ ...identity_photo });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { identity_photo, account_video } = business;

    let idPhotoPayload = {
      action_type: "identity_photo",
      ...(identity_photo && {
        identity_photo:
          typeof identity_photo === "string" ? "" : identity_photo,
      }),
    };

    let vidPayload = {
      tribe_account_id,
      ...(account_video && {
        account_video: typeof account_video === "string" ? "" : account_video,
      }),
    };

    idPhotoPayload = cleanPayload(idPhotoPayload);
    vidPayload = cleanPayload(vidPayload);

    const formDataIdPhoto = new FormData();
    const formDataVid = new FormData();

    Object.keys(idPhotoPayload).forEach((key) => {
      formDataIdPhoto.append(key, idPhotoPayload[key]);
    });
    Object.keys(vidPayload).forEach((key) => {
      formDataVid.append(key, vidPayload[key]);
    });

    dispatch(
      actions.updateUserCompliance({
        formDataIdPhoto,
        formDataVid,
        tribe_account_id: currentBusiness.tribe_account_id,
        tribeAccountID: currentBusiness.tribe_account_id,
        tribeRef: currentBusiness.account_tribe_ref,
      })
    );
  };

  const idStatus = complianceStatuses?.stats?.idStatus;

  const errors = handleSetComplianceStatuses(
    compliance || currentBusiness
  )?.userErrs;
  return (
    <form onSubmit={handleSubmit} className="w-full ">
      <div className="sm:hidden flex justify-center items-center  w-full px-[22px] py-3 bg-white border-b-1/2 border-grey-bordercolor absolute top-0 right-0 left-0">
        <Link className="text-grey-text text-sm" to="/dashboard/compliance/">
          Back
        </Link>

        <span className="text-sm text-black w-full text-center">
          Account holder
        </span>
      </div>
      <p className="sm:hidden text-grey-text text-xs font-thin pt-8 px-2 sm:px-5">
        Tell us about your identity to get you setup
      </p>
      <div
        ref={errorContainerRef}
        className="flex flex-col justify-start items-start space-y-1 w-full absolute sm:relative top-[80px] sm:top-0 left-0 right-0"
      >
        {errors
          ?.filter((item) => item)
          .map((item, i) => (
            <div
              key={i + item}
              className="w-full flex justify-center items-center bg-red text-white text-xs font-thin px-4 py-2"
            >
              <span className="">
                <WarningWhite className="stroke-current mr-2" />
              </span>
              {item}
            </div>
          ))}
      </div>
      <div
        className={`space-y-2 w-full px-2 sm:px-5 pt-0 sm:pt-0`}
        style={{
          marginTop: `${
            width < 640 ? errorContainerRef?.current?.scrollHeight : 0
          }px`,
        }}
      >
        <div className="space-y-4 py-4 w-full pb-4">
          <div className="flex justify-between items-end w-full">
            <FileInput
              placeholder="Upload a valid government issued ID"
              title="Upload a valid government issued ID"
              file={
                convertingImage?.identity_photo
                  ? { name: "Converting .heic image to .jpg" }
                  : business.identity_photo
              }
              dis
              handleLoading={(status) =>
                setConvertingImage({ identity_photo: status })
              }
              isDisabled
              type="pdf_image"
              className={
                files?.identity_photo?.url ? "w-[calc(100%-95px)]" : "w-full"
              }
              isError={
                idStatus === "rejected" &&
                typeof business.identity_photo === "string"
              }
              icon={
                (idStatus === "accepted" || idStatus === "approved") && (
                  <Success className="w-[34px] h-[34px]" />
                )
              }
            />
            {files?.identity_photo?.url &&
              (files?.identity_photo?.type === "pdf" ? (
                <a
                  href={files?.identity_photo?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="button" text="Preview" />
                </a>
              ) : (
                <Button
                  type="button"
                  text="Preview"
                  isDisabled={convertingImage?.identity_photo}
                  onClick={() =>
                    setImageModal({
                      show: true,
                      type: files?.identity_photo?.url,
                    })
                  }
                />
              ))}
          </div>
          {/* {currentBusiness?.account_type?.toLowerCase() !== "merchant" &&
            <div className="flex flex-col justify-start items-start space-y-2 w-full">
              <span className="text-grey-text text-xs font-light">
                Upload video
              </span>
              <div className="flex justify-start items-start bg-grey-whitesmoke rounded-lg text-xs font-thin border border-blue px-4 py-3">
                <span className="mt-1">
                  {" "}
                  <InfoPurple className="stroke-current mr-2" />
                </span>
                We need you to upload video not more than 10 sec for claims saying
                “My name is (Your name) and I want a bani account” for
                confirmation.
              </div>
            </div>
          } */}
          {/* File upload */}
          {/* {currentBusiness?.account_type?.toLowerCase() !== "merchant" &&
            <div className="flex flex-col justify-start items-start w-full">
              <FileInput
                placeholder="Upload video (not more than 50mb)"
                file={
                  convertingImage?.account_video
                    ? { name: "Converting .heic image to .jpg" }
                    : business.account_video
                }
                handleLoading={(status) =>
                  setConvertingImage({ account_video: status })
                }
                onChangeFunc={(val) => handleChange("account_video", val[0])}
                isDisabled={updatingUserCompliance}
                type="video"
                isError={vidError}
              />

              <div className={`h-4 w-100 mb-${vidError ? "2" : "0"}`}>
                {vidError && (
                  <ErrorMessage message="Video size cannot be over 50mb" />
                )}
              </div>

              <Button
                whiteBg
                text="Live record"
                onClick={() => setShowVideoModal(true)}
                icon={<RecordIcon className="stroke-current" />}
                fullWidth
                borderColor="blue"
                textColor="blue"
                textClass="font-light"
              />
            </div>} */}
          {/* File upload end */}
        </div>
      </div>
      {imageModal.show && (
        <ImageModal
          active={imageModal.show}
          toggler={() => setImageModal({ ...emptyImageModal })}
          photo={imageModal.type}
        />
      )}
    </form>
  );
};

export default AccountHolderForm;
