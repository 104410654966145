import React from "react";
import { useSelector } from "react-redux";
import BreadCrumbs from "components/General/BreadCrumbs";
import Accordion from "./Accordion";
import AccountHolderForm from "./AccountHolderForm";
import BusinessAccountForm from "./BusinessAccountForm";
import BankVerificationForm from "./BankVerificationForm";

const ComplianceList = () => {
  const { complianceStatuses } = useSelector((state) => state.compliance);

  return (
    <div className="flex flex-col justify-start items-center w-full space-y-7">
      <div className="flex flex-col md:flex-row justify-start items-start h-full w-full space-y-7 md:space-y-0 md:space-x-5 md:mb-14 lg:mb-10 pr-2">
        <BreadCrumbs
          className=""
          links={[
            {
              name: "Merchant Account",
              link: `/dashboard/merchants/${complianceStatuses?.tribe_account_id}`,
            },
            { name: "Merchant Compliance" },
          ]}
        />
      </div>

      <div className="flex flex-col justify-start items-center w-full h-full space-y-6">
        {/* Accordion one */}

        <div className="flex flex-col justify-start items-start w-full md:w-[65%] lg:w-1/2">
          <h3 className="text-sm mb-3 text-left">User’s verification</h3>
          <Accordion
            status={complianceStatuses.userStats}
            title="Account holder"
            body="Tell us about your identity to get you setup"
          >
            <AccountHolderForm />
          </Accordion>
        </div>

        {/* Accordion two */}

        <div className="flex flex-col justify-start items-start w-full md:w-[65%] lg:w-1/2">
          <h3 className="text-sm mb-3">Business details</h3>
          <Accordion
            status={complianceStatuses.businessStats}
            title="Business account setup"
            body="Tell us more about your business to get your account setup"
          >
            <BusinessAccountForm />
          </Accordion>
        </div>

        {/* Accordion three */}

        <div className="flex flex-col justify-start items-start w-full md:w-[65%] lg:w-1/2">
          <h3 className="text-sm mb-3">Bank verification number</h3>
          <Accordion
            status={complianceStatuses?.stats?.bvnStatus}
            title="Bank verification number"
            body="Please verify your BVN to get your account setup"
          >
            <BankVerificationForm />
          </Accordion>
        </div>

        {/* Accordion end */}
      </div>
    </div>
  );
};

export default ComplianceList;
