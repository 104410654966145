import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    errorTrue: (state, action) => {
      return {
        ...state,
        error: {
          header: action.payload.header,
          body: action.payload.body,
        },
      };
    },
    errorFalse: (state, action) => {
      return {
        ...state,
        error: null,
      };
    },
  },
});

export const { errorFalse, errorTrue } = errorSlice.actions;

export default errorSlice.reducer;
