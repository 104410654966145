import { getHeaders } from "utils/request";
import { apiInstance2 } from "../utils/apiInstance";

const apis = {
  loginUser: (data) =>
    apiInstance2("/account/login/", {
      method: "POST",
      body: data,
    }),

  logoutUser: ({ headers }) =>
    apiInstance2("/account/logout/", {
      method: "POST",
      headers,
    }),

  signupUser: (data) =>
    apiInstance2("/account/signup/", {
      method: "POST",
      body: data,
    }),

  lostAtSea: (data) =>
    apiInstance2("/account/reset_password/", {
      method: "POST",
      body: data,
    }),

  fetchUserBusinesses: () => apiInstance2("/account/all_tribe_accounts/"),
  getProfileData: ({ tribeRef }) =>
    apiInstance2("/account/my_profile_detail/", {
      headers: { ...getHeaders({ tribeRef }) },
    }),
  updatePassword: (payload) =>
    apiInstance2("/account/change_password/", {
      data: payload,
      method: "POST",
    }),
  checkIf2faEnabled: () => apiInstance2("/account/mfa_status_check/"),
  enable2fa: (payload) =>
    apiInstance2("/account/enable_account_mfa/", {
      method: "POST",
      data: payload,
    }),
  disable2fa: (payload) =>
    apiInstance2("/account/disable_my_mfa/", { method: "POST", data: payload }),
  fetchNotificationSettings: ({ tribeRef, tribeAccountID }) =>
    apiInstance2("/misc/check_notifications/", {
      headers: { ...getHeaders({ tribeRef }) },
      method: "POST",
      data: { tribe_account_id: tribeAccountID },
    }),
  adjustNotificationSettings: ({ tribeRef, ...payload }) =>
    apiInstance2("/misc/add_update_notification/", {
      headers: { ...getHeaders({ tribeRef }) },
      method: "POST",
      data: payload,
    }),
  verifyOTP: (payload) =>
    apiInstance2("/account/validate_pass_mfa/", {
      method: "POST",
      data: payload,
    }),
  activateTeamMemberAccount: (payload) =>
    apiInstance2("/account/activate_tribe_member_account/", {
      method: "POST",
      data: payload,
    }),
  editLoginEmail: (payload) =>
    apiInstance2("/account/change_login_email/", {
      method: "POST",
      data: payload,
    }),
};

export default apis;
