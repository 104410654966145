import { apiInstance2 } from "utils/apiInstance";

const apis = {
  getAccountCompliance: ({ tribe_account_id }) =>
    apiInstance2(`/trapmen/tribe_compliance_details/${tribe_account_id}/`, {
      method: "GET",
    }),
};

export default apis;
