import { REQUEST_TYPES } from "utils/appConstant";
import { apiInstance2 } from "utils/apiInstance";

const { POST } = REQUEST_TYPES;

const apis = {
  searchMerchants: ({ data, page }) =>
    apiInstance2(`/trapmen/list_tribe_accounts/?page=${page}`, {
      method: POST,
      data,
    }),

  getMerchantDetails: ({ tribe_account_id }) =>
    apiInstance2(`/trapmen/fetch_tribe_details/${tribe_account_id}/`, {
      method: "GET",
    }),

  getMerchantTransactions: ({ payload, page }) =>
    apiInstance2(`/trapmen/list_general_transactions/?page=${page}`, {
      method: "POST",
      body: payload,
    }),

  searchMerchantTransactions: ({ payload }) =>
    apiInstance2(`/trapmen/direct_general_tx_search/`, {
      method: "POST",
      body: payload,
    }),

  getMerchantTransactionsGraphData: (payload) =>
    apiInstance2(`/trapmen/month_on_month_dc_report/`, {
      method: "POST",
      body: payload,
    }),
  getTransactionDetails: ({ transaction_id, tribeRef }) =>
    apiInstance2(`/trapmen/single_general_transaction/${transaction_id}/`, {
      headers: {
        "MERCHANT-TRIBE-REF": tribeRef,
      },
    }),
};

export default apis;
