import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/General/Button/Button";
import InputField from "components/General/FormInputs/InputField";
import OnboardingDefault from "components/OnboardingDefault";
import { isEmail } from "utils/validations";
import useLoginSetup from "hooks/loginSetup";
import signInSlice from "./slice";

YupPassword(Yup);

const INITIAL_FORM_STATE = {
  email: "",
  password: "",
};

const FORM_VALIDATION = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string(),
});

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, user, otp_details } = useSelector((state) => state.signIn);
  const { logUserIn } = useLoginSetup();

  const { actions } = signInSlice;

  useEffect(() => {
    if (user) {
      const { first_name, last_name, token, user_type, captain_perm } = user;
      logUserIn(
        { first_name, last_name, token, user_type, captain_perm },
        "/dashboard/merchants"
      );
    }
  }, [user]);

  const handleMoveToLostAtSea = () => navigate("/lostatsea");

  const handleOnSubmit = ({ email, password }) => {
    dispatch(actions.signInUser({ username: email, password }));
  };

  useEffect(() => {
    if (!isEmpty(otp_details)) {
      navigate("/verify-otp");
    }
  }, [otp_details]);

  return (
    <OnboardingDefault>
      <Helmet>
        <title>Sign In - Bani</title>
      </Helmet>
      <h2 className="section-heading"> Sign in to your account</h2>

      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          handleOnSubmit(values);
        }}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formik) => {
          return (
            <Form className="flex flex-col justify-start items-start space-y-6 w-full">
              <div className="text-sm w-full flex flex-col justify-start items-start space-y-3">
                <span className="text-[14px] md:text-base">Email</span>
                <InputField name="email" placeholder="Email address" />
              </div>
              <div className="relative text-sm w-full flex flex-col justify-start items-start space-y-3">
                <div className="flex flex-row justify-between items-center w-full">
                  <span className="text-black text-[14px] md:text-base">
                    Password
                  </span>
                  <span
                    className="text-blue cursor-pointer text-[14px]"
                    onClick={handleMoveToLostAtSea}
                  >
                    Forgot password?
                  </span>
                </div>{" "}
                <InputField
                  name="password"
                  placeholder="Password"
                  type="password"
                />
              </div>
              <Button
                type="submit"
                text="Sign in"
                fullWidth
                isLoading={loading}
                isDisabled={
                  !!(
                    formik.values.password.length < 1 ||
                    !isEmail(formik.values.email)
                  )
                }
              />
            </Form>
          );
        }}
      </Formik>
    </OnboardingDefault>
  );
};

export default SignIn;
