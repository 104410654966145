import React from "react";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

const Select = ({
  label,
  options,
  name,
  onChange,
  async,
  labelControl,
  menuStyles,
  ...rest
}) => {
  const classNames =
    "w-full text-base border-slate-300 placeholder-slate-400 placeholder:text-grey cursor-pointer";
  const styles = {
    control: (base, state) => ({
      ...base,
      height: "44px",
      borderRadius: 8,
      border: "1px solid #E1E1E1 !important",
      cursor: "pointer",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
      zIndex: 20,
      cursor: "pointer",
      border: "1px solid #E1E1E1",
      top: "40px",
      borderRadius: 8,
      ...menuStyles,
    }),
    option: (base, state) => ({
      ...base,
      fontSize: "16px",
      backgroundColor: (state.isFocused || state.isSelected) && "#F5F6FA",
      cursor: "pointer",
      color: "#000",
    }),
  };
  return (
    <div className="w-full searchable-select-component">
      {label && (
        <div className="flex mb-[12px] justify-between">
          <label className="text-grey general-input-label">{label}</label>
          {labelControl || null}
        </div>
      )}
      {async ? (
        <AsyncSelect
          cacheOptions
          options={options}
          onChange={(selected) => onChange(selected, { name, value: selected })}
          styles={styles}
          className={classNames}
          {...rest}
        ></AsyncSelect>
      ) : (
        <ReactSelect
          options={options}
          onChange={(selected) => onChange(selected, { name, value: selected })}
          styles={styles}
          className={classNames}
          {...rest}
        ></ReactSelect>
      )}
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  async: PropTypes.bool,
  labelControl: PropTypes.any,
  menuStyles: PropTypes.object,
};

export default Select;
