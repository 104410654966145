import React from "react";
import PropTypes from "prop-types";
import Modal from "components/General/Modal/Modal/Modal";
import ModalBody from "components/General/Modal/ModalBody/ModalBody";

const ImageModal = ({ active, toggler, photo }) => {
  return (
    <Modal size="md" active={active} toggler={toggler} noPadding>
      <ModalBody>
        <div className="w-full h-full">
          {photo && (
            <img src={photo} alt="file" className="w-full h-full rounded-lg" />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
ImageModal.propTypes = {
  active: PropTypes.bool,
  toggler: PropTypes.func,
  photo: PropTypes.string,
};
export default ImageModal;
