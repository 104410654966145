import React from "react";
import PropTypes from "prop-types";
import clsx from "classnames";
import { Link } from "react-router-dom";

import { useAuth } from "hooks/auth";
import { trimArrayOfObject } from "utils/formatter";
import { ReactComponent as LogOut } from "assets/icons/logout.svg";
import { ReactComponent as Customers } from "assets/icons/customers.svg";

import { ReactComponent as Logo } from "assets/logos/faint-black.svg";
import BackDrop from "components/Layout/BackDrop";

const links = [
  {
    title: "Merchants",
    link: "/dashboard/merchants",
    icon: <Customers className="stroke-current" />,
  },
  {
    title: "Shoppers",
    link: "/dashboard/shoppers",
    icon: <Customers className="stroke-current" />,
  },
];

const SideNav = ({ sidenavOpen, setSidenavOpen, withBackDrop }) => {
  const { logout } = useAuth();

  const handleLinks = (url) => {
    return {
      to: url,
    };
  };

  return (
    <>
      {withBackDrop && (
        <BackDrop
          active={sidenavOpen}
          onClick={() => setSidenavOpen(!sidenavOpen)}
          className="z-[9996]"
        />
      )}
      <aside
        className={clsx(
          `w-[200px] sidenav left-0 z-[9997] h-screen md:py-4 flex mt-[80px] lg:h-[calc(100vh_-_80px)] flex-col flex-grow  transition-transform duration-150 ease-in-out overflow-y-auto`,
          { "translate-x-[0]": sidenavOpen },
          { "-translate-x-[200px]": !sidenavOpen },
          { "lg:hidden !mt-0 h-screen": withBackDrop },
          "lg:translate-x-0 fixed bg-white z-[9997]"
        )}
      >
        {withBackDrop && (
          <div className="pt-5 px-3 mb-[30px]">
            <Logo className="w-[63.73px] h-[20px]" />
          </div>
        )}

        <div className="w-full md:h-full space-y-[21px] md:space-y-6 pb-[15px]">
          <div className=" flex flex-col justify-start items-start pl-3 md:pl-6 w-full cursor-pointer transition-all duration-1000 ease-in-out">
            {trimArrayOfObject(links).map(({ title, icon, link }) => (
              <Link key={title} {...handleLinks(link)}>
                <div
                  className={`flex justify-center mt-[15px] items-center hover:text-blue text-grey-text text-sm space-x-2 ${
                    location.pathname.includes(link) && "text-blue"
                  }`}
                >
                  {icon}
                  <span className="text-current text-[14px] md:text-[16px]">
                    {title}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="md:mt-auto space-y-3 md:space-y-[15px] pt-6 pb-[30px] pl-3 md:pl-6 bg-grey-whitesmoke/[.2]  flex flex-col w-full cursor-pointer transition-all duration-150 ease-in-out border-t border-grey-border">
          <div
            className="flex items-center hover:text-blue text-grey-text text-sm !mb-3 space-x-2"
            onClick={logout}
          >
            <LogOut className="stroke-current" />
            <span className="text-current text-[14px] md:text-[16px]">
              Logout
            </span>
          </div>
        </div>
      </aside>
    </>
  );
};

SideNav.propTypes = {
  sidenavOpen: PropTypes.bool,
  withBackDrop: PropTypes.bool,
  setSidenavOpen: PropTypes.func,
};
export { links };
export default SideNav;
