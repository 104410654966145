export const defaultColors = {
  primary: "#5444F2",
  white: "#fff",
  dark1: "#2D2D2D",
  dark2: "#4A5050",
  grey: "#C1C1C1",
  grey2: "#65717C",
  orange: "#FFF4F0",
  purple: "#F6F2FF",
  purple2: "#6d61e0",
  green: "#ECFEFE",
  green2: "#A0DDE2",
  green3: "#40BBC3",
  pink: "#F6E4F0",
  black: "var(--color-black)",
  transparent: "var(--color-transparent)",
  redError: "var(--color-red)",
};
