import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import clsx from "classnames";
import transactionsSlice from "../slice";
import CircleLoader from "components/General/CircleLoader/CircleLoader";
import { getMerchantStatus } from "utils/functions";
import { Button } from "components/General/Button";

const { actions } = transactionsSlice;
const MerchantDetails = ({ tribe_account_id }) => {
  const dispatch = useDispatch();
  const { merchantDetails, loading } = useSelector(
    (state) => state.transactions
  );

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.getMerchantDetails({ tribe_account_id }));
  }, []);

  return (
    <div className="bg-white flex flex-col justify-start items-start w-full h-fit rounded-lg px-4 border-1/2 border-grey-bordercolor pb-6 sm:pb-0">
      <div className="pt-5 pb-3 md:py-[23px] flex items-center justify-between">
        <h3 className="text-black text-base !font-helvetica-neue whitespace-nowrap flex items-center">
          Shopper Details
        </h3>
      </div>
      {loading ? (
        <div className="flex flex-grow justify-center items-center h-full w-full min-h-76">
          <CircleLoader blue />
        </div>
      ) : (
        <div className="w-full md:pb-[17px]">
          <div className={clsx("flex flex-col space-y-4")}>
            <div className="flex flex-col justify-start items-start space-y-2.5 md:space-y-8 md:pb-4 mb-1 md:border-b-1/2 md:border-b-grey-bordercolor">
              <div className="flex justify-between items-center w-full space-y-2">
                <span className="text-12 text-grey-text leading-none">
                  Business name
                </span>
                <div className="text-black text-base leading-none break-words text-right max-w-[145px] sm:max-w-[160px]">
                  {merchantDetails.account_trade_name}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-2.5 md:space-y-8 md:pb-4 mb-1 md:border-b-1/2 md:border-b-grey-bordercolor">
              <div className="flex justify-between items-center w-full space-y-2">
                <span className="text-12 text-grey-text leading-none">
                  Account holder name
                </span>
                <div className="text-black text-base leading-none break-words text-right max-w-[145px] sm:max-w-[160px]">
                  {merchantDetails?.account_holder_details?.first_name}{" "}
                  {merchantDetails?.account_holder_details?.last_name}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-2.5 md:space-y-8 md:pb-4 mb-1 md:border-b-1/2 md:border-b-grey-bordercolor">
              <div className="flex justify-between items-center w-full space-y-2">
                <span className="text-12 text-grey-text leading-none">
                  Phone number
                </span>
                <div className="text-black text-base leading-none break-words text-right max-w-[145px] sm:max-w-[160px]">
                  {merchantDetails?.account_holder_details?.phone}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-2.5 md:space-y-8 md:pb-4 mb-1 md:border-b-1/2 md:border-b-grey-bordercolor">
              <div className="flex justify-between items-center w-full space-y-2">
                <span className="text-12 text-grey-text leading-none">
                  Email Address
                </span>
                <div className="text-black text-base leading-none break-words text-right max-w-[145px] sm:max-w-[160px]">
                  {merchantDetails?.account_holder_details?.email}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-2.5 md:space-y-8 md:pb-4 mb-1 md:border-b-1/2 md:border-b-grey-bordercolor">
              <div className="flex justify-between items-center w-full space-y-2">
                <span className="text-12 text-grey-text leading-none">
                  Status
                </span>
                <div className="text-black text-base leading-none break-words text-right max-w-[145px] sm:max-w-[160px]">
                  {getMerchantStatus(merchantDetails?.account_status)}
                </div>
              </div>
            </div>

            <Button
              text="View compliance details"
              whiteBg
              textClass="!text-black font-medium"
              onClick={() =>
                navigate(`/dashboard/compliance/${tribe_account_id}`)
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

MerchantDetails.propTypes = {
  tribe_account_id: PropTypes.string,
};

export default MerchantDetails;
