import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  compliance: {},
  complianceStatuses: {},
  loadingAccountCompliance: true,
  updatingUserCompliance: false,
  updatingBusinessCompliance: false,
  updatingBvnCompliance: false,
  formErrors: {},
  overviewImageDetails: {},
  errors: false,
};

const complianceSlice = createSlice({
  name: "compliance",
  initialState,
  reducers: {
    resetLoader: (state, action) => {
      state.loadingAccountCompliance = true;
    },
    setComplianceStatuses: (state, action) => {
      return {
        ...state,
        complianceStatuses: action.payload,
      };
    },
    fetchAccountCompliance: (state, action) => {
      state.loadingAccountCompliance = true;
    },
    fetchAccountComplianceSuccess: (state, action) => {
      return {
        ...state,
        loadingAccountCompliance: false,
        compliance: action.payload,
      };
    },
    fetchAccountComplianceError: (state, action) => {
      return {
        ...state,
        loadingAccountCompliance: false,
        errors: action.payload,
      };
    },

    setFormErrors: (state, action) => {
      return {
        ...state,
        formErrors: action.payload,
      };
    },
    updateUserCompliance: (state, action) => {
      state.updatingUserCompliance = true;
    },
    updateUserComplianceSucess: (state, action) => {
      state.updatingUserCompliance = false;
      state.errors = false;
    },
    updateUserComplianceError: (state, action) => {
      state.updatingUserCompliance = false;
      state.errors = action.payload;
    },

    updateBusinessCompliance: (state, action) => {
      state.updatingBusinessCompliance = true;
    },
    updateBusinessComplianceSucess: (state, action) => {
      state.updatingBusinessCompliance = false;
      state.errors = false;
    },
    updateBusinessComplianceError: (state, action) => {
      state.updatingBusinessCompliance = false;
      state.errors = action.payload;
    },

    updateBvnCompliance: (state, action) => {
      state.updatingBvnCompliance = true;
    },
    updateBvnComplianceSucess: (state, action) => {
      state.updatingBvnCompliance = false;
      state.errors = false;
    },
    updateBvnComplianceError: (state, action) => {
      state.updatingBvnCompliance = false;
      state.errors = action.payload;
    },
  },
});

export default complianceSlice;
